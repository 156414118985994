function selectedRows() {
    var items = new Array();
    $('.item:checked').each(function() {
        items.push($(this).data('item-id'));
    });
    return items;
}

$(function() {
    $('.chosen-select').chosen({});

    $("[data-toggle='tooltip']").tooltip();
    $("#filter").change(function() {
        if($(this).val() === '')
            $(".exam-item").show();
        else {
            $(".exam-item").hide();
            $("." + $(this).val()).show();
        }
    });
    /**
     * Date Picker
     */
    var datePickerObject = {
        weekStart: 1,
        language: "bg",
        autoclose: true,
        format: "yyyy-mm-dd",
        todayHighlight: true
    }

    if($('.examDate').data())
        $('.examDate').datepicker(datePickerObject);

    $('#codeForm').submit(function() {
        return confirm($('#codeFormMessage').val());
    });

    $('[type=radio]').click(function() {
        if($(this).val() == '0') {
            $('#secondExam').css('visibility', 'hidden');
            $('#secondExam .form-control').removeAttr('required');
            $('#secondExamSubmit').css('visibility', 'visible');
        } else {
            $('#secondExam').css('visibility', 'visible');
            $('#secondExam .form-control').attr('required');
            $('#secondExamSubmit').css('visibility', 'visible');
        }
    });


    $('#toggle').change(function() {
        $('.item').prop('checked', $(this).prop('checked'));
    });

    $('table input[type="checkbox"]').change(function() {
        $('.table-buttons').css('visibility', $('.item').is(":checked") ? 'visible' : 'hidden');
    });

    $('#activate').submit(function() {
        $(this).find('#ids').val(selectedRows());
    });
    
    $.confirm.options = {
        confirmButtonClass: "btn-danger",
        cancelButtonClass: "btn-default",
        confirmButton: "Да",
        cancelButton: "Не",
        dialogClass: "modal-dialog"
    }
    
    $('.complete-exam').confirm({
        title: "Приключване на участието в програмата",
        text: "Сигурни ли сте, че искате да приключите участието в програмата на този пациент?",
        confirm: function() {
            $('#complete-exam').submit();
        },
        cancel: function() {
            // nothing to do
        }
    });
    
    // $('.toggle-notifications').confirm({
    //     title: "Известяване на пациента за дневната доза",
    //     text: "Сигурни ли сте, че искате да спрете известяването на дневната доза за този пациент?",
    //     confirm: function() {
    //         $('#toggle-notifications').submit();
    //     },
    //     cancel: function() {
    //         // nothing to do
    //     }
    // });
    
    
    $('.toggle-notifications').click(function(event){
        event.preventDefault();
        var question;
        if ($(this).hasClass('btn-danger'))
            question = "Сигурни ли сте, че искате да спрете известяването на дневната доза за този пациент?";
        else
            question = "Сигурни ли сте, че искате да включите известяването на дневната доза за този пациент?";
        $.confirm({
            confirmButtonClass: "btn-danger",
            cancelButtonClass: "btn-default",
            confirmButton: "Да",
            cancelButton: "Не",
            dialogClass: "modal-dialog",
            title: "Известяване на пациента за дневната доза",
            text: question,
            confirm: function() {
                $('#toggle-notifications').submit();
            },
            cancel: function() {
                // nothing to do
            }
        });
    });
    
    
    
    
    
    
    
    
    $("#dose").change(function(){
        $.confirm.options = {
            text: "Сигурни ли сте, че искате да промените дозата с:<br/>"+$("#dose option:selected").text()
        }
    });
    $('.add-dose').click(function(event){
        event.preventDefault();
        $.confirm({
            confirmButtonClass: "btn-danger",
            cancelButtonClass: "btn-default",
            confirmButton: "Да",
            cancelButton: "Не",
            dialogClass: "modal-dialog",
            title: "Промяна на дневната доза",
            text: "Сигурни ли сте, че искате да промените дозата с:<br/>"+$("#dose option:selected").text(),
            confirm: function() {
                $('#add-dose').submit();
            },
            cancel: function() {
                // nothing to do
            }
        });
    });
    
    $('.button-onco').click(function(event){
        event.preventDefault();
        if ($("#examDate").val() != '' && $("select[name='examTime'").val() != '')
        {
            $.confirm({
                confirmButtonClass: "btn-success",
                cancelButtonClass: "btn-default",
                confirmButton: "Да",
                cancelButton: "Не",
                dialogClass: "modal-dialog",
                title: "Добавяне на нова дата",
                text: "Потвърждавате ли датата " +$("#examDate").val() + " от " + $("select[name='examTime'").val() +"ч. за следващ преглед на този пациент?",
                confirm: function() {
                    $('#form-onco').submit();
                },
                cancel: function() {
                    // nothing to do
                }
            });
        }
        else
        {
            $.confirm({
                confirmButtonClass: "hidden",
                cancelButtonClass: "btn-primary",
                cancelButton: "Затвори",
                dialogClass: "modal-dialog",
                title: "Внимание",
                text: "Изберете дата и час за следващия преглед.",
                confirm: function() {
                    //
                },
                cancel: function() {
                    // nothing to do
                }
            });
        }
    });
    
    $('.delete').confirm({
        title: "Изтриване на дозата/дозите",
        text: "Сигурни ли сте че искате да продължите?",
        confirm: function() {
            $('#delete').find('#ids').val(selectedRows());
            $('#delete').submit();
        },
        cancel: function() {
            // nothing to do
        }
    });
});